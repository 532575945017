<template>
  <div class="v_safe_center g-flex-column">
    <head-back backRouterName="mycenter">
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>账号安全</span>
      </template>
    </head-back>

    <div class="v-safe-center-content">
      <!-- 卡片一 -->
      <ul class="v-safe-center-userinfo">
        <li class="v-safe-center-userinfo-item g-flex-align-center" @click="uploadAvatarClick">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left g-flex-align-center">
            <span class="v-safe-center-item-left-title">头像</span>
          </div>  

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <img :src="userInfo.head_img" alt="">
          </div>
        </li>

         <van-uploader id="uploader-box" :after-read="afterRead" :before-read="beforeRead" v-show="false"></van-uploader>



        <li class="v-safe-center-userinfo-item g-flex-align-center" @click="$router.push({ name: 'changename' })">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left g-flex-align-center">
            <span class="v-safe-center-item-left-title">昵称</span>
          </div> 

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <span class="v-safe-center-item-right-text">{{userInfo.nick_name ? userInfo.nick_name :'去设置' }}</span>
            <i class="iconfont icon-you"></i>
          </div>
        </li>

        <!-- 声音开关 -->
        <li class="v-safe-center-userinfo-item g-flex-align-center">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left g-flex-align-center">
            <span class="v-safe-center-item-left-title">订单声音提示</span>
          </div> 

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <van-switch v-model="$global.orderMusic" size="20" @change="switchChange" active-value="1" inactive-value="0" active-color="#F9436B" inactive-color="#dcdee0" />
          </div>
        </li>

        <li class="v-safe-center-userinfo-item g-flex-align-center" @click="$router.push({ name: 'changepwd' })">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left">
            <span class="v-safe-center-item-left-title">修改登录密码</span>
          </div>  

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <i class="iconfont icon-you"></i>
          </div>
        </li>
      </ul>

      <!-- 卡片二 -->
      <ul class="v-safe-center-userinfo v-safe-center-second">
        <li class="v-safe-center-userinfo-item g-flex-align-center" @click="changeCardClick">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left g-flex-align-center">
            <i class="iconfont icon-shenfenzhengkapian"></i>
            <span class="v-safe-center-item-left-title">身份信息</span>
          </div>

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <span class="v-safe-center-item-right-text" v-show="userInfo.real_status == 0">去绑定</span>
            <span class="v-safe-center-item-right-text" v-show="userInfo.real_status == 1">审核中</span>
            <span class="v-safe-center-item-right-text" v-show="userInfo.real_status == 2">审核通过</span>
            <span class="v-safe-center-item-right-text" v-show="userInfo.real_status == 3">审核不通过</span>
            <i class="iconfont icon-you"></i>
          </div>
        </li>

        <li class="v-safe-center-userinfo-item g-flex-align-center" @click="$router.push({ name: 'changebank'})">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left g-flex-align-center">
            <i class="iconfont icon-yinxingqia"></i>
            <span class="v-safe-center-item-left-title">银行卡</span>
          </div>

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <span class="v-safe-center-item-right-text" v-if="!userInfo.bank">去绑定</span>
            <span class="v-safe-center-item-right-text" v-if="userInfo.bank.status === 0">审核未通过</span>
            <span class="v-safe-center-item-right-text" v-if="userInfo.bank.status === 1">审核中</span>
            <span class="v-safe-center-item-right-text" v-if="userInfo.bank.status === 2">审核通过</span>
            
            <i class="iconfont icon-you"></i>
          </div>
        </li>

        <li class="v-safe-center-userinfo-item g-flex-align-center" @click="$router.push({ name: 'changeallpay' })">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left g-flex-align-center">
            <i class="iconfont icon-zhifubao"></i>
            <span class="v-safe-center-item-left-title">支付宝</span>
          </div>  

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <span class="v-safe-center-item-right-text" v-if="!userInfo.alipay">去绑定</span>
            <span class="v-safe-center-item-right-text" v-if="userInfo.alipay.status === 0">审核未通过</span>
            <span class="v-safe-center-item-right-text" v-if="userInfo.alipay.status === 1">审核中</span>
            <span class="v-safe-center-item-right-text" v-if="userInfo.alipay.status === 2">审核通过</span>
            <i class="iconfont icon-you"></i>
          </div>
        </li>

      </ul>

      <!-- tips -->
      <p class="v-safe-center-tips">
        账户信息
        <span> (身份证信息必须和银行卡信息一致,微信和QQ至少绑定一项) </span>
      </p>

      <!-- 卡片三 -->
      <ul class="v-safe-center-userinfo v-safe-center-second v-safe-center-third">
        <li class="v-safe-center-userinfo-item g-flex-align-center" v-for="(item, index) in platformList" :key="index" @click="bindAccount(index.toString(), item.id)">
          <!-- 左侧 -->
          <div class="v-safe-center-item-left g-flex-align-center">
            <i v-show="index === 0" class="iconfont icon-changyonglogo25"></i>
            <i v-show="index === 1" class="iconfont icon-zhifu-jingdong"></i>
            <i v-show="index === 2" class="iconfont icon-pinduoduo"></i>
            <span class="v-safe-center-item-left-title">绑定【{{item.title}}】账号</span>
          </div>

          <!-- 右侧 -->
          <div class="v-safe-center-item-right g-flex-align-center">
            <i class="iconfont icon-you"></i>
          </div>
        </li>
      </ul>

      <!-- tips -->
      <p class="v-safe-center-tips">
       <span>任务平台账号(账号真实姓名必须要和身份证信息一致)</span> 
        身份证信息审核中可以添加第三方账号
      </p>
    </div>

  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { apiUpLoadHeadImg, apiEditInfo, apiGetUserInfo, apiGetPlatformAccountList } from '../../utils/api.js'
  export default {
    components: { HeadBack },
    data() {
      return {
        userInfo: this.$global.userInfo,
        checked: true,
        limitPng: ['image/jpeg','image/jpg','image/png','image/JPEG','image/JPG','image/PNG'],
        // 平台列表
        platformList: [
          {
            id: '',
            title: '',
            account: [],
            user_account_id: ''
          }
        ],
      }
    },
    created() {
      this.apiGetPlatformAccountListHandel()
    },
    methods: {
      //开关声音
      switchChange(val) {
        this.$global.setOrderMusic(val)
        if(val == 1) return this.$notify({ background: '#F9436B', message: '已开启', duration: 1000, });
        if(val == 0) return this.$notify({ background: '#dcdee0', message: '已关闭', duration: 1000 });
      },
      // 获取平台列表
      async apiGetPlatformAccountListHandel() {
        const { success, data } = await apiGetPlatformAccountList()
        if(!success) return
        this.platformList = data.list
        console.log(data)
      },
      // 点击身份信息
      changeCardClick() {
        if(this.userInfo.real_status === 2) return this.$toast('身份信息审核通过后,禁止修改!')
        return this.$router.push({ name: 'changeidcard' })
      },
      
      // 点击头像
      uploadAvatarClick() {
        document.getElementById('uploader-box').click()
      },

      // 上传之前
      beforeRead(file) {
        if(this.limitPng.indexOf(file.type) === -1) {
          this.$toast('请上传jpeg、jpg、png格式的图片')
          return false
        }
        return true
      },

      // 上传
      async afterRead(file) {
        // formdata提交
        let formData = new FormData()
        formData.append('file', file.file)
        this.$global.loadingShow = true
        const { success, data } = await apiUpLoadHeadImg(formData)
        if(!success) return false
        this.apiEditInfoHandel(data.url)
      },
      
      // 修改头像
      async apiEditInfoHandel(url) {
        this.$global.loadingShow = true
        const { success, data } = await apiEditInfo({ head_img: url })
        if(!success) return
        this.apiGetUserInfoHandel()
        console.log(data)
      },

      // 获取用户信息
      async apiGetUserInfoHandel() {
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.userInfo = data.data
        this.$global.setUserInfo(data.data)
        console.log(data)
      },

      // 绑定账户
      bindAccount(val, id) {
        this.$router.push({ name: 'bindaccountlist', params: { type: val, id: id }})
      }
    }
  }
</script>

<style lang="scss">
.v_safe_center {
  background: #F1F1F1;
  height: 100%;
  .v-safe-center-content {
    padding: 10px 12px;
    flex: 1;
    overflow: auto;
    .v-safe-center-userinfo {
      background: $white;
      border-radius: 8px;
      .v-safe-center-userinfo-item {
        padding: 12px 14px;
        .v-safe-center-item-left {
          flex: 1;
          .v-safe-center-item-left-title {
            font-size: 14px;
            color: #292929;
          }
        }
        .v-safe-center-item-right {
          color: #292929;
          font-size: 14px;
          .v-safe-center-item-right-text {
            max-width: 250px;
            text-align: right;
            @include textManyOverflow();
          }
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
          .iconfont {
            padding-left: 10px;
            font-size: 22px;
            color: #7D929C;
          }
        }
      }
    }

    .v-safe-center-second {
      margin-top: 14px;
      .v-safe-center-userinfo-item {
        .v-safe-center-item-left {
          .iconfont {
            color: $main_color;
            font-size: 20px;
          }
          .v-safe-center-item-left-title {
            padding-left: 12px;
          }
        }
      }
    }

    .v-safe-center-tips {
      letter-spacing: 1px;
      padding: 12px 16px 0px 16px;
      color: $main_color;
      font-size: 12px;
      line-height: 20px;
      span {
        color: #6E6D6C;
      }
    }

    .v-safe-center-third {
      .v-safe-center-userinfo-item {
        .v-safe-center-item-left {
          .iconfont {
            color: $main_color;
            font-size: 20px;
          }
        }
        &:nth-of-type(3) {
        .v-safe-center-item-left {
            .iconfont  {
              font-size: 16px;
              padding-right: 4px;
            }
          }
        }
      }
    }
  }
}
</style>